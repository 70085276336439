import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Response, Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.api.data.export.strategy.d.ts' {
    export class ApiDataExportStrategy extends self.workbox.strategies.Strategy {
        constructor(options: StrategyOptions);

        _handle(request: Request, handler: InstanceType<typeof StrategyHandler>): Promise<Response | undefined>;
    }
}
